import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import {
  BrandAddressEntityToBrandAddressMapper,
  BrandEntityToBrandMapper,
  BrandListEntityToBrandsMapper,
} from "../../../features/brand/data/mappers/brand.mapper";
import { Brand } from "../../../features/brand/domain/models/brand";
import { BrandNotSelectedError } from "../../errors/brand-not-selected-error";
import { HttpClient } from "../http-client";
import { StorageKeys } from "../local-storage.service";
import { safeLocalStorage } from "../../utils/safe-storage";

@Injectable()
export class BrandService {
  private readonly path = "brand";
  public readonly currentBrand$ = new BehaviorSubject<Brand | undefined>(
    undefined,
  );
  public readonly userBrands$ = new BehaviorSubject<Brand[] | undefined>(
    undefined,
  );
  private readonly brandMapper = new BrandEntityToBrandMapper(
    new BrandAddressEntityToBrandAddressMapper(),
  );

  constructor(private readonly http: HttpClient) {}

  // this must be used just for pages after the BrandResolver
  public get currentBrand(): Brand {
    if (!this.currentBrand$.value) {
      throw new BrandNotSelectedError();
    }

    return this.currentBrand$.value;
  }

  // this must be used just for pages after the BrandResolver
  public get currentBrandId(): number {
    return this.currentBrand.id;
  }

  public get lastSelectedBrandId(): number | undefined {
    const id = safeLocalStorage.getItem(StorageKeys.CurrentBrandId);
    return id !== null ? Number(id) : undefined;
  }

  get currentBrandCompanyName(): string | undefined {
    try {
      return this.currentBrand.companyName;
    } catch (error) {
      return undefined;
    }
  }

  public get(id: number): Observable<Brand> {
    return this.http
      .get(`${this.path}/${id}`)
      .pipe(map(({ body }) => this.brandMapper.map(body)));
  }

  private setCurrentBrand(brand: Brand | undefined): void {
    this.currentBrand$.next(brand);

    if (brand) {
      safeLocalStorage.setItem(StorageKeys.CurrentBrandId, brand.id.toString());
    } else {
      safeLocalStorage.removeItem(StorageKeys.CurrentBrandId);
    }
  }

  public clearCurrentBrand(): void {
    this.currentBrand$.next(undefined);
    safeLocalStorage.removeItem(StorageKeys.CurrentBrandId);
  }

  public async setCurrentBrandId(brandId: number): Promise<Brand> {
    const currentBrand = await lastValueFrom(this.get(brandId));
    this.setCurrentBrand(currentBrand);
    return currentBrand;
  }

  public getBrands(offset = 0, limit = 50): Observable<Brand[]> {
    let url = this.path + "?x=1";

    if (offset) {
      url = url + `&offset=${offset}`;
    }

    if (limit) {
      url = url + `&limit=${limit}`;
    }

    const brandListMapper = new BrandListEntityToBrandsMapper(this.brandMapper);

    return this.http.get(url).pipe(
      map(({ body }) => {
        const brands = brandListMapper.map(body);
        this.userBrands$.next(brands);
        return brands;
      }),
    );
  }

  public getExportFileUrl(filename: string, name = ""): string {
    return this.http.createRequestUrlV1(
      `public/export-file?filename=${filename}&name=${name}`,
    );
  }

  public getUploadBrandLogoUrl(): string {
    const brandId = this.currentBrand.id;
    return this.http.createRequestUrlV1(`brand/${brandId}/logo`);
  }

  public deleteBrandLogo(): Observable<void> {
    const brandId = this.currentBrand.id;
    return this.http.del<any>(`brand/${brandId}/logo`).pipe(map(() => {}));
  }

  public hasBrandProvidedPartnerUrlFeatureEnabled(): boolean {
    return environment.features.brandProvidedPartnerUrl.brandIds.includes(
      this.currentBrand.id,
    );
  }

  public hasBetaCampaignTypeFeatureEnabled(): boolean {
    return environment.features.betaCampaignType.brandIds.includes(
      this.currentBrand.id,
    );
  }
}
