import {
  AbstractControl,
  FormArray,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { AppDataPostTags } from "../models/appData";

export enum CapitalizationLimit {
  GoogleAds = 5,
  CompanyName = 3,
}

export class CustomFormValidators {
  public static readonly NUMERIC_REGEX = /^[0-9]+(\.?[0-9]+)?$/;
  public static readonly EMOJI_REGEX =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/i;
  public static readonly YOUTUBE_URL_REGEX =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  public static readonly URL_BASE_PATTERN =
    "(" + // open domain/IP group
    "(" + // open domain name group
    "(((www\\.){1})|(?!(www\\.){1}))" + // Separate custom validation for "www.", to avoid domains like "www.com"
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})" + // validate domain name
    ")" + // close domain name group
    "|" + // OR
    "((\\d{1,3}\\.){3}\\d{1,3})" + // validate ip (v4) address
    ")" + // close domain/IP group
    "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // validate query string
    "(\\#[-a-zA-Z\\d_]*)?$"; // validate fragment locator

  public static readonly URL_WITH_OBLIGATORY_NO_PROTOCOL_PATTERN =
    "^(?!(([\\da-z][\\da-z.-]+):){1})" + CustomFormValidators.URL_BASE_PATTERN;

  public static readonly URL_WITH_OPTIONAL_PROTOCOL_PATTERN =
    "^(?:http(s)?:\\/\\/)?" + CustomFormValidators.URL_BASE_PATTERN;

  public static readonly URL_WITH_OBLIGATORY_PROTOCOL_PATTERN =
    "^(http|https?:\\/\\/)+" + CustomFormValidators.URL_BASE_PATTERN;

  public static readonly EMAIL_REGEX = // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // at least 6 characters, at least one lower case and one number
  public static readonly PASSWORD_PATTERN =
    "^(?=.*?[a-zA-Z])(?=.*?[0-9]).{6,}$";
  public static readonly NOT_TWO_CONSECUTIVE_DOTS_REGEX =
    "^[^.]+(?:.[^.]+)*[^.]*$";
  public static readonly NO_URL_PROTOCOL_REGEX =
    /^.*(http:\/\/|https:\/\/).*$/m;
  public static readonly GOOGLE_ADS_FORBIDDEN_CHARS = [
    "!",
    "@",
    "#",
    '"',
    "`",
    "'",
    "|",
  ];

  private static readonly ERROR_BODY = { valid: false };

  public static googleAdsChars(
    control: AbstractControl<string>,
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    for (const char of CustomFormValidators.GOOGLE_ADS_FORBIDDEN_CHARS) {
      if (control.value.includes(char)) {
        return {
          forbiddenChars: CustomFormValidators.ERROR_BODY,
        };
      }
    }

    return null;
  }

  public static twoConsecutiveDots(
    control: AbstractControl<string>,
  ): ValidationErrors | null {
    if (!control?.value) {
      return null;
    }

    if (
      control.value.startsWith(".") ||
      control.value.endsWith(".") ||
      control.value.includes("..")
    ) {
      return {
        twoConsecutiveDots: CustomFormValidators.ERROR_BODY,
      };
    }

    return null;
  }

  public static youtubeUrlValidator(
    control: AbstractControl<string>,
  ): ValidationErrors | null {
    const url = control.value;

    if (!url) {
      return null;
    }

    const urlMatch = control.value.match(
      CustomFormValidators.YOUTUBE_URL_REGEX,
    );

    if (!urlMatch || urlMatch[7].length !== 11) {
      return {
        youtubeUrlValidate: CustomFormValidators.ERROR_BODY,
      };
    }

    return null;
  }

  public static number = (Control: AbstractControl<string>) =>
    CustomFormValidators.pattern(
      CustomFormValidators.NUMERIC_REGEX,
      "number",
    )(Control);

  public static email = (Control: AbstractControl<string>) =>
    CustomFormValidators.pattern(
      CustomFormValidators.EMAIL_REGEX,
      "email",
    )(Control);

  public static uppercaseLimit(limit: number): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
      const uppercaseLimitPattern = `\\b[A-Z]{${limit},}\\b`;
      const regex = new RegExp(uppercaseLimitPattern, "m");

      return CustomFormValidators.errorPattern(
        regex,
        "uppercaseLimit",
      )(control);
    };
  }

  public static url = (Control: AbstractControl<string>) =>
    CustomFormValidators.pattern(
      CustomFormValidators.URL_WITH_OBLIGATORY_NO_PROTOCOL_PATTERN,
    )(Control);

  public static urlWithOptionalProtocol = (Control: AbstractControl<string>) =>
    CustomFormValidators.pattern(
      CustomFormValidators.URL_WITH_OPTIONAL_PROTOCOL_PATTERN,
      "urlWithOptionalProtocol",
    )(Control);

  public static urlWithObligatoryProtocol = (
    Control: AbstractControl<string>,
  ) =>
    CustomFormValidators.pattern(
      CustomFormValidators.URL_WITH_OBLIGATORY_PROTOCOL_PATTERN,
      "urlWithObligatoryProtocol",
    )(Control);

  public static noUrlProtocol = (Control: AbstractControl<string>) =>
    CustomFormValidators.errorPattern(
      CustomFormValidators.NO_URL_PROTOCOL_REGEX,
      "noUrlProtocol",
    )(Control);

  public static noEmojis = (Control: AbstractControl<string>) =>
    CustomFormValidators.errorPattern(
      CustomFormValidators.EMOJI_REGEX,
      "includeEmojis",
    )(Control);

  public static minElementsValidator(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const arr = control as FormArray;
      return arr.length >= min
        ? null
        : { minElements: { requiredCount: min, actualCount: arr.length } };
    };
  }

  public static maxElementsValidator(max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const arr = control as FormArray;
      return arr.length <= max
        ? null
        : { maxElements: { requiredCount: max, actualCount: arr.length } };
    };
  }

  /**
   * This method is a substitution of the native `Validations.pattern`
   * that allows an id as an input so multiple patterns can be used.
   * It also allows the pattern to be RegExp or a pattern as string.
   */
  public static pattern(pattern: RegExp | string, id = "pattern"): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      let isValid;
      if (typeof pattern === "string") {
        isValid = new RegExp(pattern).test(control.value);
      } else {
        isValid = pattern.test(control.value);
      }

      return isValid
        ? null
        : {
            [id]: CustomFormValidators.ERROR_BODY,
          };
    };
  }

  /**
   * Unlike "pattern" validator, "errorPattern" uses the pattern
   * to identify the errors in the FormControl value.
   */
  public static errorPattern(
    pattern: RegExp | string,
    id = "errorPattern",
  ): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      let isNotValid;
      if (typeof pattern === "string") {
        isNotValid = new RegExp(pattern).test(control.value);
      } else {
        isNotValid = pattern.test(control.value);
      }

      return isNotValid
        ? {
            [id]: CustomFormValidators.ERROR_BODY,
          }
        : null;
    };
  }

  public static hasUnrecognizedTag(postTags: AppDataPostTags[]): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
      const hasUnrecognizedTag =
        postTags.reduce(
          (total, tag) => total + control.value.split(tag.tag).length - 1,
          0,
        ) !==
        control.value.split("{{").length - 1;
      return hasUnrecognizedTag
        ? { unrecognizedTag: CustomFormValidators.ERROR_BODY }
        : null;
    };
  }
}

/**
 * This ErrorStateMatcher is used for template-driven forms to show the errors
 * while the user is writing (control is marked as dirty). Not necessary when
 * using reactive forms.
 */
export class GenericErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: AbstractControl<string> | null,
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

export function emailListValidator(): ValidatorFn {
  return ({ value }: AbstractControl<string[]>): ValidationErrors | null => {
    if (!value || value.length === 0) {
      return null;
    }

    const isNotValid = !value.every((mail) =>
      CustomFormValidators.EMAIL_REGEX.test(mail),
    );

    return isNotValid ? { emailList: true } : null;
  };
}

export function noDuplicatesValidator(): ValidatorFn {
  return ({ value }: AbstractControl<string[]>): ValidationErrors | null => {
    if (!value || value.length === 0) {
      return null;
    }

    const sorted = value.slice().sort();
    let hasDuplicates = false;

    for (let i = 0; i < sorted.length - 1; i++) {
      if (sorted[i + 1] === sorted[i]) {
        hasDuplicates = true;
        break;
      }
    }

    return hasDuplicates ? { noDuplicates: true } : null;
  };
}
